@import 'variables';


.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .rbc-timeslot-group {
    flex: 1;
  }
}


.rbc-timeslot-group {
  border-bottom: 1px solid $cell-border;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;

  .rbc-event {
    border: 1px solid $event-border;
    display: flex;
    max-height: 100%;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
  }

  .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto;
  }

  .rbc-event-content {
    width: 100%;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
  }

  .rbc-time-slot {
    border-top: 1px solid tint-color($cell-border, 10%);
  }
}

.rbc-time-slot {
  flex: 1 0 0;

  &.rbc-now {
    font-weight: bold;
  }
}

.rbc-day-header {
  text-align: center;
}

.rbc-day-slot .rbc-event {
  position: absolute;
  z-index: 2;
}
